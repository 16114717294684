export const ConstantsDzsAp = {
  PLAYLIST_TRANSITION_DURATION: 300,
  'DEBUG_STYLE_1': 'background-color: #aaa022; color: #222222;',
  'DEBUG_STYLE_2': 'background-color: #7c3b8e; color: #ffffff;',
  'DEBUG_STYLE_3': 'background-color: #3a696b; color: #eeeeee;',
  'DEBUG_STYLE_ERROR': 'background-color: #3a696b; color: #eeeeee;',
  URL_WAVESURFER_HELPER_BACKUP: 'https://zoomthe.me/assets/dzsap-wave-generate.js',
  WAVESURFER_MAX_TIMEOUT: 20000,
  URL_JQUERY: 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js',
  DEBUG_STYLE_PLAY_FUNCTIONS: 'background-color: #daffda; color: #222222;',
  ERRORED_OUT_CLASS: 'errored-out',
  ERRORED_OUT_MAX_ATTEMPTS: 5
}

export const DZSAP_PLAYER_CLASS_FOOTER_PLAYER = 'dzsap_footer';
export const DZSAP_PLAYER_CLASS_LOADED = 'dzsap-loaded';
export const DZSAP_PLAYER_CLASS_FOR_STICK_TO_BOTTOM_SHOW = 'audioplayer-loaded';
export const DZSAP_PLAYER_CLASS_STICK_TO_BOTTOM = 'dzsap-sticktobottom';
export const DZSAP_PLAYER_PLACEHOLDER_CLASS_STICK_TO_BOTTOM = 'dzsap-sticktobottom-placeholder';
export const DZSAP_PLAYER_TYPE_FOR_ACCEPTING_FEED = 'fake';
export const DZSAP_PLAYER_ATTR_PLAY_FROM_LAST_POS = 'last';

export const DZSAP_SCRIPT_SELECTOR_MAIN_SETTINGS = '#dzsap-main-settings';
export const DZSAP_SCRIPT_SELECTOR_KEYBOARD = '#dzsap-keyboard-controls';
export const DZSAP_VIEW_PLAYER_PLAYING_EASED_TIMEOUT = 500;
